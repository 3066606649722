import { iBlog } from "../interfaces/blog.interface";

export const BlogTop: iBlog = {
    link: "/blog/les-meilleurs-soins-pour-cheveux-crepus-en-2024",
    title: "Les meilleurs soins pour <strong> cheveux crépus en 2024</strong>",
    baseline: `Quels sont les meilleurs soins pour cheveux crépus ? Pas facile de s’en sortir avec toutes les gammes existantes sur le marché pour hydrater ses cheveux.`,
    cover: {
        jpg: "/assets/img/blog/soins_crepus_2024.png",
        webp: "/assets/img/blog/soins_crepus_2024.webp"
    },
    subheading: "Conseil d'Audrey",
}

