import { iBlog } from "../interfaces/blog.interface";

export const BlogTop: iBlog = {
    link: "/blog/5-signes-que-tu-dois-couper-tes-pointes",
    title: "Cheveux texturés&nbsp;: 5 signes que <strong>tu dois couper tes pointes</strong>",
    baseline: `Couper ses pointes quand on a les cheveux bouclés, frisés ou crépus rime souvent avec calvaire.`,
    cover: {
        jpg: "/assets/img/blog/pointe_cover.jpg",
        webp: "/assets/img/blog/pointe_cover.webp"
    },
    subheading: "Conseil d'Audrey",
}

